import React from "react";
import { Helmet } from "react-helmet";
import { SiteData } from "../consts/SiteData";
import { Container, Header } from "semantic-ui-react";
import { MainContents } from "../components/MainContents";
import PageLayout from "../components/PageLayout";

const ContactPage = () => {
  return (
    <PageLayout>
      <Helmet>
        <html lang="ja" />
        <title>{SiteData.PAGES.CONTACT.TITLE}</title>
      </Helmet>
      <MainContents pageTitle="お問い合わせ">
        <Container textAlign="center">
          <Header
            as="a"
            target="_blank"
            href="mailto:contact@clsl.net"
            color="teal"
          >
            contact@clsl.net
          </Header>
        </Container>
      </MainContents>
    </PageLayout>
  );
};

export default ContactPage;
